/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Row, Col, Input, Button } from 'reactstrap';
import 'wever-maps/src/assets/style.scss';

import '@/assets/scss/components/tab-sheet-action.scss';
import View from '@/components/view';
import Api from '@/services/api';
import { t } from '@/services/translator';

const Index = ({ match }) => {
  const [file, setFile] = useState();
  const [communityId, setCommunity] = useState();
  const [loading, setLoading] = useState(false);

  const upload = async () => {
    setLoading(true);
    Api.defaultContentType = 'multipart/form-data';
    const body = new FormData();
    body.append('file', file);
    try {
      await Api.post(`/imports/referencing_club/${communityId}`, body);
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  };

  return (
    <View className="pt-5">
      <Row className="mt-5">
        <Col lg={6}>
          <div>{t('adminapp_form_community_id')}</div>
          <Input type="text" placeholder="id communauté parente" value={communityId} onChange={(e) => setCommunity(e.target.value)} />
        </Col>
        <Col lg={12} className="mt-2">
          <div>{t('adminapp_file')}</div>
          <Input type="file" onChange={(e) => setFile(e.target.files[0])} accept="text/csv" />
        </Col>

        <Col sm="12" md="4" lg="3" className="mt-5">
          <Button type="button" onClick={upload} disabled={loading}>
            {t('admin_app_import_file')} {loading ? ' ....loading' : ''}
          </Button>
        </Col>
      </Row>
    </View>
  );
};
export default Index;
