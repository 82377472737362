import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Card, CardBody } from 'reactstrap';

import { Datepicker, Input, Checkbox, AutocompleteServer } from '@/components/form';
import ResourceForm from '@/components/resources/form';
import View from '@/components/view';
import security from '@/services/security';
import { t } from '@/services/translator';

import Geometries from './geometries';
import Instances from './instances';

const Index = ({ match }) => (
  <View>
    <ResourceForm
      resource="ReportPattern"
      id={match.params.id}
      initialValues={{ query: '[]', geometries: { points: [], lines: [], polygons: [] } }}
    >
      {({ submitting, renderActions, values, ...props }) => (
        <>
          <Card>
            <CardBody className={submitting ? 'loading' : ''}>
              <Row className="m-0">
                <Col lg={12}>
                  <Row>
                    <Col lg="3">
                      <Input name="name" />
                    </Col>
                    <Col lg="3">
                      <Datepicker name="startDate" />
                    </Col>
                    <Col lg="3">
                      <Datepicker name="endDate" />
                    </Col>
                    <Col lg="3">
                      <AutocompleteServer name="server" />
                    </Col>
                    <Col lg="6">
                      <Checkbox  name="autoImportOnCreate" />
                    </Col>
                    <Col lg="6">
                      <Checkbox name="autoImportOnUpdate" />
                    </Col>
                    <Col lg="6 d-flex justify-content-center align-items-center">
                      {(security.isGranted('ROLE_ADMIN') || security.isGranted('ROLE_SUPER_ADMIN')) && values.owner ? (
                        <Link to={`/users/${values.owner.id}`}>
                          {t('user')} : {values.owner.id}
                        </Link>
                      ) : null}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Geometries {...props} />
              <Instances {...props} />
            </CardBody>
          </Card>
          <div className="mt-4 mb-4">{renderActions()}</div>
        </>
      )}
    </ResourceForm>
  </View>
);

Index.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
  }).isRequired
};

export default Index;
