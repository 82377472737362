import { useField } from 'formik';
import React from 'react';
import { Col, Row, Card, CardBody, Button } from 'reactstrap';

import _Form from '@/components/form';
import notifier from '@/services/notifications';
import getResource from '@/services/resources';
import { t } from '@/services/translator';

import ServerDefaultField from './server-default';

const { Input, Checkbox, Autocomplete } = _Form;

const General = ({ submitting }) => {
  const [id] = useField({ name: 'id' });
  const [report] = useField({ name: 'report' });

  const exportCommunityReport = async () => {
    let response = null;
    try {
      response = await getResource('Community').exportCommunityReport(id.value);
    } catch (e) {
      notifier.error(t('download'), t('error_cannot_fetch_resource'));
      return;
    }

    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export-csv.zip');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  return (
    <Card>
      <CardBody className={submitting ? 'loading' : ''}>
        <Row>
          <Col className="col-6">
            <Row>
              <Col className="col-12">
                <Input name="key" placeholder="ex: COM_029K92" pattern="/^[a-zA-Z_0-9]+$" />
              </Col>
              <Col className="col-12">
                <Input name="url" placeholder="https://..." />
              </Col>
              <Col className="col-12">
                <Checkbox name="public" type="checkbox" />
              </Col>
              <Col className="col-12">
                <Checkbox name="reportWithShape" type="checkbox" />
              </Col>
            </Row>
          </Col>
          <Col className="col-6">
            <Col lg={12}>
              <Autocomplete name="report" clearable resource="Report" display="name" tag="name" />
              <span className="fs-small mb-2">{report.value}</span>
            </Col>
            <Col lg={12}>
              <Autocomplete
                name="accountManagers"
                multiple
                clearable
                autoload
                resource="User"
                display="username"
                tag="username"
                filters={{ roles: ['ROLE_ACCOUNT_MANAGER', 'ROLE_ACCOUNT_MANAGER', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'] }}
              />
            </Col>
            <Col lg={12}>
              <ServerDefaultField name="serverDefault" />
            </Col>
          </Col>
          <Col lg="6">
            <Autocomplete name="accountType" clearable autoload resource="AccountType" display="name" tag="name" />
          </Col>
          <Col lg={6}>
            <Autocomplete
              name="documentOfficials"
              multiple
              clearable
              resource="DocumentOfficial"
              display="name"
              tag="name"
              autoload
            />
          </Col>
          <Col sm="2">
            <Button color="info" size="sm" target="_blank" download onClick={() => exportCommunityReport()}>
              {t('communitiesapp_export_csv_community_report')}
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default General;
